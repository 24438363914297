import { LoanRoleDto } from "src/backend";
import { useGetLoanByIdQuery } from "src/services/loanApi";
import { useSearchUsersQuery } from "src/services/userApi";
import { createRecipientList } from "src/utils/loan/create-recipient-list";
import { isRoleABorrower } from "src/utils/user/is-role-a-borrower";

import { useLenderEmployees } from "../use-lender-employees";
import { useUser } from "../use-user";

interface GetRecipientsUsersProps {
    loanId: string;
    type: 'ALL' | 'BORROWERS' | 'LENDERS' | 'NOT_ON_LOAN';
}

export const useGetRecipientsUsers = (props: GetRecipientsUsersProps) => {
    const { data: loan = { loanRoles: [] as LoanRoleDto[], lender: { id: null } } } = useGetLoanByIdQuery(props.loanId, {
        skip: !props.loanId
    });
    const loggedInUser = useUser();

    const { data: searchUsers = [] } = useSearchUsersQuery({
        slice: ''
    }, {
        skip: !loggedInUser.isLender
    })

    const { employees = [] } = useLenderEmployees({
        lenderId: loan.lender.id,
        skip: !loggedInUser.isLender || !props.loanId
    });
    const { loanRoles } = loan;
    const loanUsersIds = loanRoles.map(({ user }) => user.id);

    const loanRolesWithLoggedCompanyRole = loanRoles.map((loanRole) => {
        if (loanRole.user.loggedCompanyRole) {
            return loanRole;
        } else {
            return {
                ...loanRole,
                user: {
                    ...loanRole.user,
                    loggedCompanyRole: loanRole.role,
                    loggedRoleGroup: loanRole.user.loggedRoleGroup
                }
            }
        }
    });

    const typeLoanRoles: LoanRoleDto[] = []
    if (props.type === 'ALL') {
        typeLoanRoles.push(...loanRolesWithLoggedCompanyRole);
    } else if (props.type === 'BORROWERS') {
        typeLoanRoles.push(...loanRolesWithLoggedCompanyRole.filter(({ role }) => isRoleABorrower(role)));
    } else if (props.type === 'LENDERS') {
        typeLoanRoles.push(...loanRolesWithLoggedCompanyRole.filter(({ role }) => !isRoleABorrower(role)));
    }


    const filteredEmployees = employees.filter(({ id }) => !loanUsersIds.includes(id));
    // combine loan roles users and employees and logged in user
    // and filter out the any duplicate users
    const users = [
        ...typeLoanRoles.map(({ user }) => user),
        ...filteredEmployees,
        loggedInUser.user
    ].filter((user, index, self) => self.findIndex(({ id }) => id === user.id) === index);

    return {
        users,
        recipientsOptions: createRecipientList(
            typeLoanRoles,
            filteredEmployees,
            loggedInUser.user,
            props.type !== 'LENDERS' ? searchUsers : []
        )
    } as const;
};